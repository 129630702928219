.container {
  background: #fff;  /* set background color to dominant color of image */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  max-width: 800px;
  width: 90%;
  text-align: center;
}

.responsive-image {
  max-width: 100%;
  height: auto;
}

.responsive-text {
  color: #000;
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

a {
  color: blue; /* set text color to blue */
  text-decoration: none; /* add underline */
}

@media only screen and (max-width: 768px) {
  .content {
    width: 100%;
    padding: 0 1rem;
  }

  .responsive-text {
    font-size: 1.5rem;
  }
}